import React, { useRef, useEffect, useState } from "react";
import { useSpring, animated } from "@react-spring/web";
import { Link } from "react-router-dom";
import * as THREE from "three";
import CLOUDS from "vanta/dist/vanta.clouds.min";
import BlogAnimation from "../../components/bloganimation/BlogAnimation";
import Navigation from '../../components/navigation/Navigation';
import { fetchDataWithAxios } from "./api";
import "./Blogpage.scss";


function BlogPage() {
  const vantaRef = useRef(null);
  const [posts, setPosts] = useState([]);
  const props = useSpring({ opacity: 1, from: { opacity: 0.2 } });


  useEffect(() => {
    vantaRef.current = CLOUDS({
      THREE,
      el: "#background_container",
      color: 0x00ffff,
      backgroundColor: 0x222222,
      blur: 10,
      zoom: 3.25,
    });

    return () => {
      if (vantaRef.current) {
        vantaRef.current.destroy();
      }
    };
  }, []);

  
  useEffect(() => {

    const fetchPosts = async () => {
      try {
        const response = await fetchDataWithAxios();
        console.log("API Response:", response);

        if (Array.isArray(response) && response.length > 0) {
          // Assuming 'items' is the array of blog posts in the response
          console.log("Fetched posts:", response)
          setPosts(response);
        } else {
          console.log("No posts found");
        }
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };

       fetchPosts();
  }, []);

  return (
    <div className="blog-page">
        <div className="blog-title">Bonk's Blog
        <div className="content">
              <div className="animation">
                <BlogAnimation />
              </div>
            </div>
          <div className="boxy">
            <div className="about-text">
              Here you'll find my writing on topics ranging from coding and the
              web industry, to entrepreneurship in the age of AI. My love for
              writing took flight while tackling a paper on International
              Entrepreneurship, Accelerators, Startups and Effectuation Theory
              back in University:{" "}
              <a
                className="paper-link"
                href="https://www.researchgate.net/profile/Carlos-Augusto-Septimio-De-Carvalho/publication/320934341_Effectuation_and_the_influence_of_social_networks_on_the_internationalization_of_accelerated_startups/links/5a034abcaca2721d41865f8e/Effectuation-and-the-influence-of-social-networks-on-the-internationalization-of-accelerated-startups.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                read here
              </a>
            </div>
          </div>
        </div>
          <div className="blog-container">
            {console.log("Posts to display", posts)}
              {posts.length > 0 ? (
              posts.map((post) => {
                  if (!post || typeof post !== 'object' || !post.id || !post.url || !post.title) { 
                    console.log("Unexpected post structure:", post); //4 debugging
                    return null;
            }
            return (
                    <div className="blog-overlay" key={post.id}>
                      <div className="blog-post">
                        <Link
                          to={{
                            pathname: `/blog/${post.id}/${post.url.split("/").pop()}`,
                            state: { postId: post.id },
                          }}>
                    <div className="post-title">
                    <p>{post.title}</p>
                        </div>
                        </Link>
                      </div>
                    </div>
            );
            })
              )  : (
               
                <animated.h1 style={props} className="recent-post">
                Coming Soon!
                </animated.h1>   
            )}
        </div>
        <section className="lets-talk">
          <div className="boxie">
          <div className="projects-title">
                <i>Let's build something together?</i>
            </div>
          <div className="button-container">
          <Link to="/contact">
              <button>Get In touch</button>
              </Link>
              <Navigation/>
          </div>
          </div>
          </section>
    </div>
  );
}

export default BlogPage;
