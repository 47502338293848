import React, { useRef, useEffect } from 'react';
//import { Link } from 'react-router-dom';
import YouTube from 'react-youtube'; 
import './CryptoProject.scss';
import * as THREE from "three";
import CLOUDS from "vanta/dist/vanta.clouds.min";
import "../../assets/images/cbud.png";
import Navigation from '../../components/navigation/Navigation';


function CryptoProject() {

    const vantaRef = useRef(null);

  useEffect(() => {
    vantaRef.current = CLOUDS({
      THREE,
      el: "#background_container",
      color: 0x00ffff,
      backgroundColor: 0x222222,
      scale: 1.5,
      blur: 10,
      zoom: 2.25,
    });

    
    return () => {
      if (vantaRef.current) {
        vantaRef.current.destroy();
      }
    };
  }, []);

  function openClientGit() {
    window.open("https://github.com/carolbonk/cryptobud-client", "_blank");
  }
  
  function openServerGit() {
    window.open("https://github.com/carolbonk/cryptobud-server", "_blank");
  }

  const videoId = 'Ndd44XDcJlw';

  const videoOpts = {
    height: '500px',
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

    return (
        <>
        <section className="crypto-project-page">
            <div className="project-header">
            <h1 className="project-title">CryptoBud</h1>
            <i>UX/UI Design, Frontend Development, Backend Development, Full Stack Web Development</i>
            </div>
                <div className="project-description">
                <h2>Project Overview</h2>
                    <p>As part of my capstone project for a Full Stack Web Development Bootcamp, 
                    I developed a social media platform specially designed for the cryptocurrency marketplace. 
                    This project involved overcoming the challenges associated with the CRUD (Create, Read, Update & Delete)
                    architecture; including the technical intricacies involved in the integration of these operations. 
                    </p>
                    <h2>My tech stack </h2>
                    <div className="boxy">
            <div className="about-especs">
            <p>
                <p><i>for the CLIENT SIDE</i></p>
                <div className="techStack">REACT, JAVASCRIPT, SASS, AXIOS, J PARTICLES, REACT INFINITE SCROLL COMPONENT, 
                HTML REACT PARSER, REACT ROUTER DOM, REACT VIS (CHARTS) - FROM UBER</div>
                <p><i>for the SERVER SIDE</i></p>
                <div className="techStack">NODE.JS, EXPRESS, KNEX, MYSQL, JWT TOKENS</div>
                <p><i>for EXTERNAL APIs</i></p>
                <div className="techStack">COINCAP API</div>
                </p>
                </div>
                </div>
                    <h2>Key Features</h2>
                    <p><b>CRUD Operations:</b> The platform integrates comprehensive CRUD operations, 
                    each with its own data flow and user interaction considerations.
                    </p>
                    <p><b>User Interface:</b>
                    The project's user interface was intuitively designed to guide 
                    users through each CRUD operation while providing real-time feedback and error handling.
                    </p>
                    <p><b>Balanced Design:</b> Achieving a balance between functionality, performance, and user experience was a 
                    meticulous endeavor that demanded thoughtful design and development.
                    </p>
                    <h2>Functionality</h2>
                    <p>
                    <i>The project includes the following features</i>
                    <p>1 - Users can register to create an account, including data such as an avatar and location information</p>
                    <p>2 - Users can publish posts, either with textual information, and/or with images and/or with charts connected
                    to real-world crypto data</p>
                    <p>3 - Those posts can be public or private. Only followers can see private posts (named "Cluster only" posts in the application)</p>
                    <p>4 - Users can follow other users</p>
                    <p>5 - Users can comment on each other's posts</p>
                    </p>
                    <h2>Charts</h2>
                    <p>
                    The user can post charts that connect to real world crypto data for a variety of 
                    coins. This data comes from the CoinCap API. The project uses the proxy pattern, 
                    so to do this, the client application first calls an API on the cryptobud server, 
                    which in turns calls the CoinCap API.
                    </p>
                    <h2>Feeds</h2>
                    <p>
                    The homepage for authenticated users displays a "feed" of posts the user has access
                    to. This uses an infinite scroll, such that posts are retrieved from the server as 
                    the user scrolls down (10 at a time). Some specific data for the posts is obtained 
                    with subsequent API requests, namely data for displaying charts and DUMP/HODL data 
                    to display on each post. 
                    </p>
                    <h2>Objective</h2>
                    <p>To create a robust and dynamic social media platform that meets user needs while 
                    offering a modern, user-friendly experience tailored to the cryptocurrency community.
                    </p>
                    </div>
                    <div className="button-container">
                    <button onClick={openClientGit}>OPEN CLIENT GITHUB
                    </button>
                    <button onClick={openServerGit}>OPEN SERVER GITHUB
                    </button>
                    </div>
            <div className="transparent-box">
        <div className="video-container">
          <YouTube videoId={videoId} opts={videoOpts}  />
        </div>
      </div>
      <div className="project-description">
      <h2>Project Structure Client Side</h2>
                          <p>
                          <i>Cryptobud has the following client structure</i>
                          <p>All of the source files are in the src folder</p>
                          <p>The react components are divided into two large subgroups/folders within 
                          the src folder: pages & components. Pages each have a unique url and hold 
                          state - they do not reside inside of other components (they are the top level
                          component). Components in the components folder are used by pages or other 
                          components and do not hold state (only props).</p>
                          <p>The styles all go in the styles subfolder of the src folder. SaSS partials
                          are in a partial subfolder of the styles folder. There is a partial for 
                          holding color variables, fonts and screen sizes for SaaS media queries.</p>
                          <p>All static assets go in the assets subfolder of the src files.</p>
                          <p>App.js mostly just handles routing to the appropriate page based on the given URL.</p>
                          </p>

      <h2>Project Structure Server Side</h2>
                          <p>
                          <i>Cryptobud has the following server structure</i>

                    <p>1 - A migrations folder where knex migrations are stored (for generating the database)</p>
                    <p>A routes folder for API requests. There are three files inside, one for charts, 
                    one for posts and one for users. All API requests that start with the "/posts" are routed to the post.js 
                    file - "/charts" maps to chart.js and "/users" to users.js.</p>
                    <p>An index.js file that sets up the routing and imports for all of the APIs</p>
                    <p>Configurable settings are stored in a .env file</p>
                    <p>A public folder that exposes resources inside with individual URLs. 
                    This is used for user avatars' and posts' images. In both cases the database only stores the URL 
                    of the image which links back to this folder.</p>
                    </p>
                    </div>
      <Navigation/>
    </section>
        </>
    );
}

export default CryptoProject;