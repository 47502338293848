import React, { useRef, useEffect } from 'react';
import './CloudBrewersPage.scss';
import * as THREE from "three";
import CLOUDS from "vanta/dist/vanta.clouds.min";
import Navigation from '../../components/navigation/Navigation';
import cloud1 from "../../assets/images/cloud1.png";
import cloud2 from "../../assets/images/cloud2.png";
import cloud3 from "../../assets/images/cloud3.png";
import cloud4 from "../../assets/images/cloud4.png";
import cloud5 from "../../assets/images/cloud5.png";
import cloud6 from "../../assets/images/cloud6.png";
import cloud7 from "../../assets/images/cloud7.png";
//import cloud8 from "../../assets/images/cloud8.mp4";

function CloudBrewers() {

    const vantaRef = useRef(null);

  useEffect(() => {
    vantaRef.current = CLOUDS({
      THREE,
      el: "#background_container",
      color: 0x00ffff,
      backgroundColor: 0x222222,
      scale: 1.5,
      blur: 10,
      zoom: 2.25,
    });

    
    return () => {
      if (vantaRef.current) {
        vantaRef.current.destroy();
      }
    };
  }, []);
/*
  const videoId = 'oDVXX4iC2LA';

  const videoOpts = {
    height: '500px',
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };
*/
  const BehanceButton = () => {
    window.open('https://www.behance.net/gallery/206709791/CloudBrewers-(Responsive)-Web-Design-Web-development', "_blank");
  };

  const CloudBrewersWebsite = () => {
    window.open('https://cloudbrewers.com/', "_blank")
  }

  const images = [cloud1, cloud2, cloud3, cloud4, cloud5, cloud6, cloud7];

    return (
        <>
        <section className="cloudbrewers-page">
        <h2 className="project-title">CloudBrewers</h2>
        <i>Web Development, Web Design, UX/UI Design, Branding</i>
            <div className="project-header">
                <div className="project-description">
                <h2>Project Overview</h2>
                <p>This project was designed considering user experience and accessibility metrics. As a platform 
                that offers web development, UX/UI design and SEO, it's main goal is to provide low code websites with 
                highly quality customizable solutions to small and medium businesses.</p>
                <h2>My tech stack </h2>
                    <div className="boxy">
            <div className="about-especs">
            <p>
                <p><i>for UX/UI DESIGN, WEB DESIGN AND DEVELOPMENT</i></p>
                <div className="techStack">CSS3, JAVASCRIPT, WORDPRESS, GITHUB, PHP, CANVA & BEHANCE</div>
                </p>
                </div>
                </div>
                <p>The ideation and design process was carried out using Figma and Canva, to ensure a cohesive visual strategy.
                The development process leveraged Javascript, various WordPress plug-ins and PHP to build a robust a dynamic website. 
                </p>
                <p>
                <h2>Key Features</h2>
                <p><b>Mobile-First Responsive Design:</b> CloudBrewers prioritizes user experience by implementing a mobile-first approach, 
                ensuring the website adaptation to various screen sizes and devices.
                </p>
                <p><b>Modern Aesthetic:</b> 
                The project aimed to deliver a high-quality, 
                user-friendly website with a modern look and feel.
                </p> 
                {''}
                </p>
                </div>
            </div>
            <div>
                <div className="button-container">
                <button onClick={BehanceButton}>OPEN CASE STUDY</button>
                <button onClick={CloudBrewersWebsite}>CHECK WEBSITE</button>
                </div>
            </div>
            <div className="transparent-box">
            <p className="project-description"></p>
        <div className="video-container">
          
        </div>
      </div>

      <div className="transparent-box">
        <div className="gallery">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`life-${index + 1}`}
            width="60%"
            margin="1rem"
          />
        ))}
      </div>
      </div>
      <Navigation/>
        </section>
        </>
    );
}

export default CloudBrewers;