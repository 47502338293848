import React, { useRef, useEffect } from "react";
import FigmaEmbed from "./FigmaEmbed";
import "./MomsMatchPage.scss";
import * as THREE from "three";
import CLOUDS from "vanta/dist/vanta.clouds.min";
import Navigation from '../../components/navigation/Navigation';

function MomsMatch() {
  const vantaRef = useRef(null);

  useEffect(() => {
    vantaRef.current = CLOUDS({
      THREE,
      el: "#background_container",
      color: 0x00ffff,
      backgroundColor: 0x222222,
      scale: 1.5,
      blur: 10,
      zoom: 2.25,
    });

    return () => {
      if (vantaRef.current) {
        vantaRef.current.destroy();
      }
    };
  }, []);

    const BehanceButton = () => {
      window.open('https://www.behance.net/gallery/206469927/Moms-Match-%28Mobile-App%29-UXUI-Design-Case-study', "_blank");
    };


  return (
<>
      <section className="moms-project-page">
          <div className="project-header">
          <h1 className="project-title">Moms Match</h1>
          <i>Usability testing, Wireframe, Prototyping, UX/UI Design </i>
          <div className="project-description">
          <h2>Project Overview</h2>
              <p>
              Moms Match connects moms who have baby products to donate with those in need, to
              including single-parenting and firsttime moms, 
              helping them to better navigate the challenges of motherhood with ease. 
              The entire UX Design process of this project, from
              start to finish, took aproximately 1 month and 3 weeks.
              A goal-directed design approach was employed, 
              utilizing qualitative research methods.
              I initially assumed that the need of the users 
              would be a platform for connecting “givers” moms (donators) with “takers” (receivers).
              </p>
              <h2>My tech stack </h2>
                    <div className="boxy">
            <div className="about-especs">
            <p>
                <p><i>for UX/UI DESIGN and MOBILE DESIGN</i></p>
                <div className="techStack">CSS3, JAVASCRIPT, WORDPRESS, GITHUB, PHP, CANVA & BEHANCE</div>
                </p>
                </div>
                </div>
                <h2>Research Method</h2>
                <p>
                I took a goal-directed design approach. I found qualitative
              research methods to be the most useful. From surveys and
              interviews with moms from various backgrounds, also analyzed data
              from reputable sources.
              However, I found out that there was also a strong desire for a supportive
              community, where moms could share experiences and advices,
              particularly related with Postpartum Depression. This insight led
              me to broaden the design approach not only to facilitate the
              exchange, but also to build a beautiful connections between moms
              all around the United States.
              </p>
                <h2>Key Features</h2>
                <p>
                <b>Community Building:</b> Beyond facilitating donations, the platform fosters 
                a supportive community where moms can share experiences and advice, 
                particularly related to Postpartum Depression.
                </p>
                <p>
                <b>User-Centered Design:</b> Insights from surveys and interviews with moms from 
                diverse backgrounds, along with data analysis from reputable sources, 
                guided the design process.
                </p>
                <h2>Objective</h2>
                <p>
                To create a platform that not only connects donor moms with those in need 
                but also builds a supportive community, enhancing the overall motherhood experience across the United States.
                </p>

            </div>
            <button onClick={BehanceButton}>OPEN CASE STUDY</button>
          </div>
        
        <div className="transparent-box">
        <div className="figma-title">My Figma Prototype</div>
          <div className="figma-container">
            <FigmaEmbed/>
          </div>
        </div>
        <Navigation/>
    </section>
    </>
  );
}

export default MomsMatch;
